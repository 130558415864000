treetools = {

  testalert: function() {
    alert("treetools!");
  },


  getCore: function() {
      schraubenliste.array.forEach(function(element) {}, this);
    }, //getCore

    //liefert alle vorhandenen werte von key in einem array
    //tools.getKeys("d_1", schraubenliste)
    getKeys: function(key, array) {
      var keys = [];
      var foo = function(item) {
        if (keys.indexOf(item[key]) == -1) {
          keys.push(item[key]);
        }

      };
      array.map(foo);

      return keys;
    },
    //

    //liefert alle objekte in "array" als array fuer die gilt key==val
    //tools.getItemsForKey("d_1", "1", schraubenliste)
    getItemsForKey: function(key, val, array) {
      //console.log(key, val);
      var foo = function(el, i, ar) {
        return el[key] == val;
      };

      return array.filter(foo);
    },

    //liefert ein array von arrays zurueck, dass fuer jeden key die zugehoerigen elemente enthaelt
    //tools.sortAndMove("d_1", schraubenliste)
    sortAndMove: function(key, array) {
      self = this;
      var arraynew = [];
      var keys = this.getKeys(key, array);
      //console.log("sortAndMove: "+keys);
      keys.forEach(function(el, i) {
          arraynew[i] = {
            "text": el,
            "children": self.getItemsForKey(key, el, array)
          };
        } //function
      );

      return arraynew;
    },

    //levels: ["VM_Name", "BeschichtungText", "d_1", "AddText0"],
    // levels: ["VM_Typ", "Kopfform", "d_1", "l_s"],
    levels: ['Kopfform', 'schaftform', 'spitzenform',  'd_1', 'l_s'],

    validnodes: [],
    validkopfformen: [],
    validtypes: [],

    buildTree: function(liste, showinvalidscrews) {
      console.log("treetools.buildTree()");
      self = this;

      self.validnodes = [];
      self.validkopfformen = [];
      self.validtypes = [];

      var firstRun = function(i, array) {

        var temparray = [];

        var nodes = [];
        if (i < self.levels.length - 1) { //weitere ebenen hinzufuegen
          array.forEach(function(el, j) {
            var keys = self.getKeys(self.levels[i], el.children); //<-- liefert die keys fuer die ebene
            var ar = self.sortAndMove(self.levels[i], el.children);

            if (self.levels[i] == "d_1") {
              ar.forEach(function(el1, k) {
                nodes.push({
                  "text": "d=" + parseFloat(el1.text.replace(',', '.')),
                  "children": firstRun(i + 1, [el1])
                });
              });
            } else {
              ar.forEach(function(el1, k) {
                nodes.push({
                  "text": el1.text,
                  "children": firstRun(i + 1, [el1])
                });
              });
            }

          }); //foreach
        } else { //letzte ebene -> endknoten setzen
          nodes = array[0].children.map( //<--auf der letzten ebene gibt es nur noch ein array
            function(element) {

              if (element.VM_Okay == "True") {
                console.log('schraube:', element);
                // self.validnodes.push(element.d_1 + element.Kopfform + element.VM_Typ);
                self.validnodes.push(element.d_1 + element.spitzenform + element.schaftform + element.Kopfform);
                // self.validkopfformen.push(element.Kopfform+ element.VM_Typ);
                self.validkopfformen.push(element.spitzenform + element.schaftform + element.Kopfform);

                self.validtypes.push(element.VM_Typ);

                return {
                  "text": "l=" + parseFloat(element[self.levels[i]].replace(',', '.')),
                  "data": element,
                  'icon': 'assets/images/screw_tick.png'
                };
              } else {
                // console.log("#a#a##a#a##a#a#");
                // console.log(element[self.levels[i]]);
                return {
                  "text": "l=" + parseFloat(element[self.levels[i]].replace(',', '.')),
                  "data": element,
                  'icon': 'assets/images/screw_cross.png'
                };
              }

            }
          );
        } //

        return nodes;
      };

      var thenodes = firstRun(0, [{
        "text": "root",
        "children": liste
      }]);

      console.log('the nodes:', thenodes);

      var createNodes = function(i, array) {
        var nodes = [];
        if (i < self.levels.length - 1) { //weitere ebenen hinzufuegen
          array.forEach(function(el, j) {
            var keys = self.getKeys(self.levels[i], el.children); //<-- liefert die keys fuer die ebene
            var ar = self.sortAndMove(self.levels[i], el.children);

            if (self.levels[i] == "d_1") {
              ar.forEach(function(el1, k) {

                // var temptext = el1.text + el1.children[0].Kopfform + el1.children[0].VM_Typ;
                var temptext = el1.text + el1.children[0].spitzenform + el1.children[0].schaftform + el1.children[0].Kopfform;


                if (self.validnodes.indexOf(temptext) !== -1) {

                  nodes.push({
                    "text": "d=" + parseFloat(el1.text.replace(',', '.')),
                    "children": createNodes(i + 1, [el1]),
                    'icon': 'assets/images/folder.svg',
                    'a_attr': {
                      'class': 'textcolor--524f53'}
                  });
                } else {
                  nodes.push({
                    "text": "d=" + parseFloat(el1.text.replace(',', '.')),
                    "children": createNodes(i + 1, [el1]),
                    'icon': 'assets/images/folder_disabled.svg',
                    'a_attr': {
                      'class': 'textcolor--afafaf'}
                  });
                }
              });
            } else {
              ar.forEach(function(el1, k) {

                // var temptext = el1.text + el1.children[0].VM_Typ;
                var temptext = el1.children[0].spitzenform + el1.children[0].schaftform + el1.children[0].Kopfform;

                if(el1.text === 'Zylinderkopf'){
                  console.log('temptext:', temptext);
                  console.log('validkopfformen:', self.validkopfformen);
                  // console.log('enthalten:', self.validkopfformen.indexOf(temptext));
                  console.log('el1.text:', el1.text);
                  console.log('el1', el1);
                  console.log('true elements:', el1.children.filter(obj => obj.VM_Okay === 'True'));
                  // console.log('enthalten:', self.validtypes.indexOf(el1.text));
                }

                // if (self.validkopfformen.indexOf(temptext) !== -1) {
                if(el1.children.filter(obj => obj.VM_Okay === 'True').length > 0){

                  nodes.push({
                    "text": el1.text,
                    "children": createNodes(i + 1, [el1]),
                    'icon': 'assets/images/folder.svg',
                    'a_attr': {
                      'class': 'textcolor--524f53'}
                  });

                } else {

                  nodes.push({
                    "text": el1.text,
                    "children": createNodes(i + 1, [el1]),
                    'icon': 'assets/images/folder_disabled.svg',
                    'a_attr': {
                      'class': 'textcolor--afafaf'}
                  });

                }
              });
            }

          }); //foreach
        } else { //letzte ebene -> endknoten setzen
          nodes = array[0].children.map( //<--auf der letzten ebene gibt es nur noch ein array
            function(element) {

              if (element.VM_Okay == "True") {
                return {
                  "text": "l=" + parseFloat(element[self.levels[i]].replace(',', '.'))+element.b_zu_ls,
                  "data": element,
                  'icon': 'assets/images/screw_tick.png',
                  'a_attr': {
                    'class': 'hint--bottom hint--success textcolor--524f53',
                    // 'data-hint': element.ToolTipText
                    'data-tooltip': element.ToolTipText,
                    'onmouseover': "tooltipshow(this, \"tooltipfenster-gruen\")",
                    'onmouseout': "tooltiphide()"

                  }
                };
              } else {
                if (showinvalidscrews) {
                return {
                  "text": "l=" + parseFloat(element[self.levels[i]].replace(',', '.'))+element.b_zu_ls,
                  "data": element,
                  'icon': 'assets/images/screw_cross.png',
                  'a_attr': {
                    'class': 'hint--bottom hint--error textcolor--afafaf',
                    // 'data-hint': element.ToolTipText
                    'data-tooltip': element.ToolTipText,
                    'onmouseover': "tooltipshow(this, \"tooltipfenster-rot\")",
                    'onmouseout': "tooltiphide()"

                  }
                };}
                else {
                  return "";
                }
              }

            }
          );
        } //

        return nodes;
      };

      return createNodes(0, [{
        "text": "root",
        "children": liste
      }]);
    }, //buildTreeFunctional

    zeichneBaum: function() {
      self = this;

    }
}
